import type { ContentTypeName } from '../../../../core//types'
import {
  mapperModuleInternalVideo,
  mapperModuleMedia,
  mapperModuleVideo
} from '../../../../core/data-layer/modules/mappers'
import {
  mapperBlockRelatedContent,
  mapperBlockKnowledgeHubChapter,
  mapperBlockCampaignsMedia
} from '../../mappers'
import { mapperBlockMetrics, mapperBlockContent, mapperBlockCallToAction } from '../../../../core/data-layer/blocks/mappers'

const relatedContentCardsImageOptions = {
  resize: true,
  lazy: true,
  sizes: {
    small: { width: 344, height: 193, aspectRatio: '16/9' }, medium: { width: 333, height: 187, aspectRatio: '16/9' }, large: { width: 456, height: 256, aspectRatio: '16/9' }
  }
}
// const thumnbnailImageOptions = {
//   resize: true,
//   lazy: true,
//   sizes: {
//     small: { width: 327, height: 327, aspectRatio: '1/1' },
//     medium: { width: 312, height: 312, aspectRatio: '1/1' },
//     large: { width: 576, height: 576, aspectRatio: '1/1' }
//   }
// }
const headerMediaImageOptions = {
  resize: true,
  lazy: true,
  sizes: { small: { width: 344, height: 193, aspectRatio: '16/9' }, medium: { width: 786, height: 442, aspectRatio: '16/9' }, large: { width: 1608, height: 904, aspectRatio: '16/9' } }
}
const videoSectionOptions = {
  resize: true,
  lazy: true,
  sizes: { small: { width: 344, height: 172, aspectRatio: '2/1' }, medium: { width: 690, height: 345, aspectRatio: '2/1' }, large: { width: 1416, height: 708, aspectRatio: '2/1' } }
}

/**
 * Page Campaigns Detail Awareness Mapper
 * Normalizes data to be used in campaigns detail awareness pages
 */
export const mapperContentKnowledgeHubCaseStudy = (data: any, options: any) => {
  if (!data) {
    return null
  }

  if (data.lazy) {
    return data
  }

  const mapItem = (item: any, options: any) => {
    if (!item) {
      return
    }
    const contentType: ContentTypeName = item.contentType
    if (contentType === 'BlockContent') {
      return {
        ...mapperBlockContent(item, options),
        type: contentType
      }
    } else if (contentType === 'ModuleMedia') {
      return {
        ...mapperModuleMedia(item, options),
        isImage: true,
        type: contentType
      }
    } else if (contentType === 'ModuleVideo') {
      return {
        ...mapperModuleVideo(item, options),
        isVideo: true,
        type: contentType
      }
    } else if (contentType === 'ModuleInternalVideo') {
      return {
        ...mapperModuleInternalVideo(item, options),
        isVideo: true,
        type: contentType
      }
    } else if (contentType === 'BlockMetrics') {
      return {
        ...mapperBlockMetrics(item),
        type: contentType
      }
    } else if (contentType === 'BlockKnowledgeHubChapter') {
      return {
        ...mapperBlockKnowledgeHubChapter(item, options),
        type: contentType
      }
    } else if (contentType === 'BlockRelatedContent') {
      return {
        ...mapperBlockRelatedContent(item, options),
        type: contentType
      }
    } else if (contentType === 'BlockCampaignsMedia') {
      return {
        ...mapperBlockCampaignsMedia(item, options),
        type: contentType
      }
    } else if (contentType === 'BlockCallToAction') {
      return {
        ...mapperBlockCallToAction(item, options),
        type: contentType
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Mapper missing for content type for ${JSON.stringify(item)}`)
    }
  }
  return {
    id: data?.sys?.id || '',
    new: 'key',
    headerMedia: mapItem(data.headerMedia, headerMediaImageOptions) || {},
    videoSection: mapItem(data.videoSection, { ...options, imageOptions: videoSectionOptions }) || {},
    eyebrow: data.eyebrow || '',
    pageTitle: data.pageTitle || '',
    headerIntroText: data.headerIntroText || null,
    metrics: mapItem(data.metrics, {}) || null,
    chaptersIntroText: data.chaptersIntroText || null,
    chapters: data.chaptersCollection?.items?.filter(Boolean).map((item: any) => mapItem(item, options)) || [],
    summaries: data.summariesCollection?.items?.filter(Boolean).map((item: any) => mapItem(item, options)) || [],
    relatedContent: mapItem(data.relatedContent, { ...options, imageOptions: relatedContentCardsImageOptions }),
    callToAction: mapItem(data.callToAction, options.getUrl) || null
    // thumbnailImage: mapItem(data.thumbnailImage, { ...options, imageOptions: thumnbnailImageOptions }) || null
  }
}
